.Navbar {

  display: flex;
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  /* position:fixed;
  top: 0;
  z-index: 9; */

}

.header_wrap {
  position: fixed;
  top: 0;
  z-index: 9;

  width: 100%;
  margin: 0 auto;
}

.navbar_wrap {

  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.03);
  background: #ffffff;

}

.Logo_warap {
  width: 100px;
  height: 100px;
  overflow: hidden;
}

.Logo_warap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}




.upper_head {
  background: linear-gradient(102.57deg, #dc0916, #f41fd6 100%, #f41fd6 0);
  padding: 15px;
}

.dflex {

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

}

.dflex p {

  font-size: 20px;
  font-weight: 400;
  cursor: pointer;


}



.upper_head_wrap {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon_color {
  color: white;
  margin-top: 1px;
  font-size: 20px;
}

.textBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  color: #fcfefe;
  font-family: "Work Sans", "Roboto Mono", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.deskMenuListItem {
  font-weight: 500;
  padding: 10px 15px;
  color: #d004a3;
  font-family: "Exo", sans-serif;
  text-transform: capitalize;
  font-size: 16px;
  transition: all .25s ease;

}

.deskMenuListItem:hover {
  cursor: pointer;
}



.appointmentbtn {
  border: none;
  display: inline-flex;
  padding: 10px 30px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 50px;
  background: #bfd2f8;
  color: #1f2b6c;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.upper_head_wrap {
  color: white;
}

.appointmentbtn:hover {
  cursor: pointer;
}

.appointmentbtnmob {
  border: none;
  padding: 6px 10px;
  border-radius: 50px;
  background: #1f2b6c;
  color: #bfd2f8;
  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.mobmenu {
  display: none;
  justify-content: center;
  align-items: center;
}

.openvector {
  font-size: 21px;
  color: #d004a3;
  ;
}

.heading {
  color: black;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.navMenu {
  color: #1f2b6c;
  font-size: 20px;
  font-weight: 400;

  position: fixed;
  align-items: center;
  top: 58px;
  right: 35px;

  z-index: 9999;
  flex-direction: column;
  padding: 0.3rem;
  min-width: 6rem;
  background: #bfd2f8;
  border-radius: 0.2rem;


}

.listItem {

  padding: 0.2rem 0.5rem;
  margin: 0.2rem;

}

@media screen and (max-width: 1024px) {


  .textBtnContainer {
    gap: 15px;
    font-size: 1.8vw;
  }



  .appointmentbtn {
    padding: 10px 30px;
    gap: 8px;
    font-size: 12px;
  }

  .dflex p {
    font-size: 14px;
  }

}


@media screen and (max-width: 768px) {
  .upper_head {
    display: none;
  }

  .Navbar {
    padding: 10px;
  }

  .upper_head {
    display: none;
  }

  .dflex p {
    font-size: 12px;
  }

  .textBtnContainer {
    gap: 8px;
    font-size: 2vw;
    display: none;
  }

  .appointmentbtn {
    padding: 5px 10px;
    font-size: 9px;
  }

  .mobmenu {
    display: flex;
  }

  .heading {
    font-size: 28px;
  }

}

@media screen and (max-width: 650px) {

  .textBtnContainer {
    font-size: 1.7vw;
  }

  .heading {
    font-size: 22px;
  }
}

@media screen and (max-width: 550px) {}

@media screen and (max-width: 425px) {}