#home {
  margin-top: 225px;

}

.home_parent {

  background: url('../../assets/newimg.jpg') no-repeat;

  filter: brightness(1.2);
  background-size: cover;
  background-position: center top 0px;
}

.hesitate {
  padding-top: 40px;
  background: url('../../assets/healthcareservices.png') no-repeat;
  background-color: #b5e79e;

}

.our_testinomial {
  height: 400px;
}

.our_testinomial h3 {

  text-align: center;
  padding-top: 40px;
  font-size: 40px;
  color: pink;

}

.testimonial_wrap {

  max-width: 1240px;
  width: 100%;
  margin: 0 auto;

}

.hesitate_wrap p {
  color: white;
  font-size: 44px;
  color: #ffffff;
  font-weight: 400;
  line-height: 120%;


}

.hesitate_wrap h2 {
  color: white;
  font-size: 40px;
  color: #ffffff;
  font-weight: 400;
  line-height: 120%;


}

.hesitate_wrap {

  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  height: 200px;
  flex-direction: column;

}


.home_wrap {
  height: 600px;

}

.doctor {

  max-width: 1240px;
  width: 100%;
  margin: 0 auto;


}

.mobdoctor {
  display: none;
  width: 100vw;
  height: auto;
}



.divider {
  width: 40px;
  height: 5px;
  background: #e12454;
  margin-bottom: 1rem;
}

.total_health {
  letter-spacing: 2px;
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 500;
  line-height: 120%;


}

h1 {
  font-size: 60px;
  line-height: 1.2;
  letter-spacing: -1.2px;
  text-transform: capitalize;
  color: #ffffff;
  margin: 20px 0;
}

.banner_wrap {

  padding: 80px 20px 160px;
  max-width: 670px;
  width: 100%;
}

.parab {

  letter-spacing: 0.5px;
  font-size: 18px;

  color: #ffffff;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 20px;
}

.btn span {
  display: block;
}

.btn {
  display: inline-block;
  font-size: 14px;
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: .5px;
  padding: 0.75rem 2rem;
  text-transform: uppercase;
  border-radius: 5px;
  border: 2px solid transparent;
  transition: all .35s ease;
  background: #e12454;
  color: #fff;
  border-color: #e12454;
  border-radius: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 200px;
}

.arrowcolor {
  color: white;
}

.card_section {
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 100px;
  position: relative;
  top: -100px;

}


.card_item {
  max-width: 400px;
  width: 100%;
  margin: 0px 10px;
  padding: 40px 30px;
  background-color: #fff;
  min-height: 320px;
  border-radius: 15px 15px 15px 15px;
  box-shadow: 0px 0px 30px 0px rgba(0, 42, 106, 0.1);


}

.time_listing li {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cfcaca;
  padding-bottom: 5px;
}

.time_listing li span {
  display: block;
}

.cardIcon {

  font-size: 50px;
  color: #223a66;

}

.card_section p {

  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  line-height: 1.6;
  font-size: 16px;
  /* color: #6F8BA4; */
  font-weight: 400;

}

.paracard {
  /* color: #6F8BA4; */
}

.card_section h5 {

  color: #223a66;
  font-size: 1.3rem;
  line-height: 30px;
  font-weight: 700;
  margin: 10px 0;

}

@media screen and (max-width: 991px) {


  .card_item {
    max-width: 340px;
  }

  .card_section {
    justify-content: space-evenly;
    gap: unset;
  }

  .hesitate_wrap {

    height: auto;
    padding: 44px;
  }

  .hesitate_wrap p {
    font-size: 40px;
  }

  .hesitate_wrap h2 {
    font-size: 24px;
  }

  .home_wrap {
    height: 480px;
  }

}

@media screen and (max-width: 768px) {
  .banner_wrap {
    padding: 100px 20px 20px;
  }

  .card_section {

    gap: 50px
  }

  .card_item {
    max-width: 450px;
  }

  h1 {
    font-size: 30px;
    margin: 10px 0;
  }

}

@media screen and (max-width: 550px) {

  .home_parent {
    background-position: center top 0px;
  }

  .banner_wrap {

    padding: 20px;
  }



}