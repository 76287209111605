.internalmedicine_wrap {
    margin: 200px auto;
    max-width: 1240px;
    width: 100%;
}

.internalmedicine_wrap p {
    color: #1f2b6c;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.63043em;
    padding-left: 40px;

}