#specialties {
  margin-top: 180px;
  margin-bottom: 70px;

  /* height: 990px; */
  background-color: pink;
  padding: 20px;
}

.speciality_wrap {
  max-width: 1240px;
  margin: 0 auto;
  width: 100%;
  /* padding: 100px 0px; */

}

.specialsmallhead {
  margin-bottom: 0.5rem;
  text-align: center;
  line-height: 1.2;
  font-weight: 700;
  font-size: 44px;
  color: #223a66;
  text-transform: uppercase;
  /* padding-top: 180px; */
}

.red_bar {
  width: 40px;
  height: 5px;
  background: #e12454;
  margin: 0 auto;
}


.speciallargehead {
  color: #1f2b6c;
  text-align: center;

  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-top: 30px;
}

.specialimg {
  width: 50px;
  height: 44px;
  flex-shrink: 0;
}

.specialimgtext {
  font-weight: 700;
  color: rgb(34, 34, 34);
  font-size: 24px;
  line-height: normal;
  margin: 0;

}

.allspecial {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 64px;
}

.specialheading {
  cursor: default;
}

.special1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  width: 100%;
}

.special {
  width: 350px;
  height: 200px;
  border-radius: 5px;
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  margin: 10px 5px;

}

.special p {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  color: #6F8BA4;
  font-weight: 400;
  margin-top: 10px;
}

.special_Icon {
  color: rgb(225, 36, 84);
  font-size: 50px;
}

.first_cell {
  display: flex;
  align-items: center;
  gap: 40px;
}



@media screen and (max-width:768px) {}


@media screen and (max-width:576px) {

  .specialsmallhead {
    font-size: 28px;
  }

  .special {
    width: 320px
  }
}