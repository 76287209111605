#doctors {
  margin: 180px 0;
}

.docimages {
  display: flex;
  gap: 20px;
  margin-top: 64px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.docimages span {
  align-items: center;
  justify-content: center;
}

.docheading {
  margin: 30px 0;
}

.smalldocheading {
  color: #159eec;
  text-align: center;

  /* Caption */
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.88px;
  text-transform: uppercase;
}

.largedocheading {
  color: #1f2b6c;
  text-align: center;
  margin-top: 8px;

  /* Display 2 */
  font-family: Yeseva One;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.docimages h5 {
  color: var #1f2b6c;
  margin-top: 16px;

  /* Body 2 */
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 25.2px */
}

.docfield {
  color: #1f2b6c;
  margin-top: 8px;

  /* Caption */
  font-family: Work Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.88px;
  text-transform: uppercase;
}


.viewprofile {
  /* Body */
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
}

.firstdoctor,
.seconddoctor,
.thirddoctor {
  border-radius: 5px 5px 0px 0px;

  background: #bfd2f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.viewprofilecontainer {
  width: 317px;
  height: 46px;
  text-align: center;
  justify-content: center;
  margin: auto auto;

  border-radius: 0px 0px 5px 5px;
  background: #1f2b6c;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.viewprofilecontainer a {
  color: #bfd2f8;
}

.fb {
  margin: 0 20px;
}

.socialmedia {
  margin: 16px 0;
}